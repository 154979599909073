<template>
  <div>
    <div id="component">
      <div class="filter-panel">
        <div class="cs-select" style="margin-right: 20px">
          <select v-model="queryParams.type" style="padding-left: 5px;">
            <option selected value="">
              全部巡检类型
            </option>
            <option
                v-for="(val, key) in judgmentAuthority()"
                :key="key"
                :value="key"
            >
              {{ val }}
            </option>
          </select>
          <i class="icon el-icon-caret-bottom"></i>
        </div>
        <input
            type="text"
            placeholder="搜索巡检计划"
            v-model="queryParams.keyword"
            class="cs-input"
        />
        <template
            v-if="[1001, 1002].includes(Number(staffInfo.dutyType))"
        >
          <div class="cs-select" style="margin-right: 20px; padding-left: 5px;">
            <select
                @change="getStaffList()"
                v-model="queryParams.orgId"
            >
              <option value="">全部部门</option>
              <option
                  v-for="org in orgList"
                  :key="org.code"
                  :value="org.code"
              >
                {{ org.name }}
              </option>
            </select>
            <i class="icon el-icon-caret-bottom"></i>
          </div>
          <div class="cs-select" style="margin-right: 30px; padding-left: 5px;">
            <select v-model="queryParams.operatorId">
              <option selected value="">
                全部创建人
              </option>
              <option
                  v-for="staff in staffList"
                  :key="staff.id"
                  :value="staff.id"
              >
                {{ staff.name }}
              </option>
            </select>
            <i class="icon el-icon-caret-bottom"></i>
          </div>
        </template>
        <button
            type="button"
            class="btn btn-primary btn-sm"
            v-on:click="_queryInspectionPlanMethod()"
        >
          查询
        </button>
      </div>

      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button
                  type="button"
                  class="btn btn-primary btn-sm sticky-right"
                  v-on:click="_openAddInspectionPlanModal()"
              >
                <svg
                    class="icon"
                    aria-hidden="true"
                >
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加巡检计划
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th class="text-center">创建时间</th>
              <th class="text-center">巡检类型</th>
              <th class="text-center">巡检计划</th>
              <th class="text-center">巡检路线</th>
              <th class="text-center">巡检日</th>
              <th class="text-center">开始时间</th>
              <th class="text-center">结束时间</th>
              <th class="text-center">创建人</th>
              <th class="text-center">巡检计划状态</th>
              <th class="text-center">巡检人</th>
              <th class="text-center">操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr
                v-for="(
                                    inspectionPlan, index
                                ) in inspectionPlans"
                :key="inspectionPlan.name + '__' + index"
            >
              <td class="text-center date-td">
                {{ inspectionPlan.createTime }}
              </td>
              <td class="text-center">
                {{ judgmentAuthority()[inspectionPlan.type] }}
              </td>
              <td class="text-center">
                {{ inspectionPlan.name }}
              </td>
              <td class="text-center">
                {{ inspectionPlan.routeName }}
              </td>
              <td class="text-center">
                <template
                    v-if="inspectionPlan.scheduleType != 1"
                >
                  <div v-if="inspectionPlan.scheduleDates && inspectionPlan.scheduleDates.length > 0"
                       class="btn btn-link btn-content"
                       @click="openViewModal(scheduleDateStr(inspectionPlan),1, '查看巡检日')"
                  >
                    {{
                      scheduleDateStr(inspectionPlan)
                    }}
                  </div>
                  <template v-else>
                    -
                  </template>
                </template>
                <template v-else> 每天</template>
              </td>
              <td class="text-center">
                {{ inspectionPlan.startTime }}
              </td>
              <td class="text-center">
                {{ inspectionPlan.endTime }}
              </td>
              <td class="text-center">
                {{ inspectionPlan.createBy }}
              </td>
              <td class="text-center">
                {{
                  inspectionPlan.enable ? "开启" : "关闭"
                }}
              </td>
              <td class="text-center">
                <template
                    v-if="
                                            inspectionPlan.performers &&
                                            inspectionPlan.performers.length > 0
                                        "
                >
                  <div class="btn btn-link btn-content"
                       @click="openViewModal(inspectionPlan.performersMap,2, '查看巡检人')"
                  >
                    {{
                      inspectionPlan.performers.toString()
                    }}
                  </div>
                </template>
                <template v-else>-</template>
              </td>
              <td class="text-center">
                <div
                    class="btn-group"
                >
                  <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    操作
                  </button>
                  <ul
                      class="dropdown-menu"
                      style="min-width: 76px; width: 100px;"
                  >
                    <li
                        @click="openSetInspectorModal(inspectionPlan)"
                    >
                      <a
                          style="width: 100%;"
                      >设置巡检人</a>
                    </li>
                    <li
                        @click="_openAddInspectionPlanModal(inspectionPlan)"
                    >
                      <a
                          style="width: 100%;"
                      >修改</a>
                    </li>
                    <li
                        @click="_openDeleteInspectionPlanModel(inspectionPlan)"
                    >
                      <a
                          style="width: 100%;"
                      >删除</a>
                    </li>
                    <li
                        @click="_openDisabledInspectionPlanModel(inspectionPlan)"
                        v-if="inspectionPlan.enable"
                    >
                      <a
                          style="width: 100%;"
                      >停用</a>
                    </li>
                    <li
                        @click="_openEnabledInspectionPlanModel(inspectionPlan)"
                        v-else
                    >
                      <a
                          style="width: 100%;"
                      >启用</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <Pagination
            name="pagination"
            componentName="Pagination"
        ></Pagination>
      </div>

      <ViewModal name="viewModal" componentName="ViewModal"></ViewModal>
      <AddInspectionPlan
          name="addInspectionPlan"
          componentName="AddInspectionPlan"
      ></AddInspectionPlan>

      <EditInspector
          name="editInspector"
          componentName="EditInspector"
      ></EditInspector>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import ViewModal from "@/components/ViewModal.vue";
import AddInspectionPlan from "@/components/AddInspectionPlan.vue";
import EditInspector from "@/components/EditInspector.vue";
import {JOB_LEVEL, DEPARTMENT_TYPES} from "@/constant";

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS = 10;
import {
  queryInspectPlanUrl,
  queryDepartmentUrl,
  queryDepartmentStaffUrl, deleteInspectPlanUrl, editInspectPlanUrl,
} from "@/requestUrl";
import {ORG_LEVEL} from "@/constant";
import CSTable from "@/components/common/CSTable";

export default {
  created() {
    window.addEventListener("keydown", this._queryInspectionPlanMethodDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this._queryInspectionPlanMethodDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this._queryInspectionPlanMethodDown);
  },
  props: {},
  components: {
    CSTable,
    Pagination,
    ViewModal,
    AddInspectionPlan,
    EditInspector,
  },
  data() {
    return {
      filterHeight: 0,
      weekMapping: {
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
        7: "日",
      },
      staffName: "",
      staffList: [],
      inspectPlanType: {
        [DEPARTMENT_TYPES.PROCTER_GAMBLE]: {
          103: "清洁",
          104: "绿化",
          105: "其它",
        },
        [DEPARTMENT_TYPES.CUSTOMER_SERVICE]: {
          203: "巡查",
          204: "其它",
        },
        [DEPARTMENT_TYPES.SECURITY]: {
          304: "巡检",
          305: "其它",
        },
        [DEPARTMENT_TYPES.ENGINEERING]: {
          413: "巡检",
          414: "维保",
          415: "其它",
        },
      },
      orgList: [],
      queryParams: {
        regionId: this.$vc.getCurrentRegion().communityId,
        type: "",
        keyword: "",
        orgId: this.$vc.getCurrentStaffInfo().orgId,
        operatorId: this.$vc.getCurrentStaffInfo().id,
        pageNum: 1,
        pageSize: 10,
      },
      inspectionPlans: [],
      staffInfo: this.$vc.getCurrentStaffInfo(),
      inspectionPlanManageInfo: {
        inspectionPlans: [],
        total: 0,
        records: 1,
        moreCondition: false,
        inspectionPlanName: "",
        states: "",
        conditions: {keyWord: "", state: ""},
      },
    };
  },

  mounted() {
    const staffInfo = this.$vc.getCurrentStaffInfo();
    if ([1001, 1002].includes(Number(staffInfo.dutyType))) {
      this.queryParams.orgId = "";
    }
    this.getOrgList();
    this.getStaffList();
    this._listInspectionPlans(DEFAULT_PAGE, DEFAULT_ROWS);
    this.$vc.getDict("inspection_plan", "state", (_data) => {
      this.inspectionPlanManageInfo.states = _data;
    });
    this.$vc.on(this.$route.path, "inspectionPlanManage", "listInspectionPlan", (_param) => {
      this._listInspectionPlans(this.queryParams.pageNum, DEFAULT_ROWS);
    });
    this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
      this._listInspectionPlans(_currentPage, DEFAULT_ROWS);
    });
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
  },
  methods: {
    _queryInspectionPlanMethodDown(e) {
      if (e.keyCode == 13) {
        this._queryInspectionPlanMethod();
      }
    },
    scheduleDateStr(inspect) {
      let scheduleDates = [];
      if (inspect.scheduleDates) {
        scheduleDates.push(...inspect.scheduleDates);
      }
      if (scheduleDates.length === 0) {
        return '-';
      }
      if (inspect.scheduleType == 2) {
        return scheduleDates
            .map((val) => {
              return "星期" + this.weekMapping[val];
            })
            .toString();
      } else {
        return scheduleDates.toString();
      }
    },
    openViewModal(viewInfo, type = 1, title) {
      let params = {};
      if (type === 1) {
        let viewArr = viewInfo.split(',');
        let contentStr = [];
        for (let key in viewArr) {
          contentStr.push(
              `<div class="mr-3">${viewArr[key]}</div>`
          )
        }
        params.content = contentStr.join("");
        this.$CSDialog.alert({
          title: title || '查看',
          messageHtml: params.content,
        })
        return;
      }
      if (type === 2) {
        let contentArr = [];
        for (let key in viewInfo) {
          if ((key - 0).toString() === "NaN") {
            contentArr.push(
                `<div class="mr-3">${key}: <div class="ml-1" style="display: inline-block;">${viewInfo[key]}</div></div>`
            );
          } else {
            if (key.length < 2) {
              contentArr.push(
                  `<div style="margin-bottom: 15px; text-align: left;"><label class="mr-3">星期${this.weekMapping[key]}</label><span class="ml-1" style="padding-left: 20px;">${viewInfo[key]}</span></div>`
              );
            } else {
              contentArr.push(
                  `<div class="mr-3">${viewInfo[key]}</div>`
              );
            }
          }
        }
        params.content = contentArr.join("");
        this.$CSDialog.alert({
          title: title || '查看',
          messageHtml: params.content,
        })
      }
    },
    getStaffList() {
      this.$fly
          .post(queryDepartmentStaffUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            departmentCode: this.queryParams.orgId,
            jobLevel: "",
            isOnTheJob: true,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.staffList = res.data;
          });
    },
    getOrgList() {
      this.$fly
          .post(queryDepartmentUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            code: "",
            name: "",
            dutyType: "",
            parentCode: "",
            level: ORG_LEVEL.DEPARTMENT,
            pageNo: 1,
            pageSize: 100,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }

            this.orgList = res.data;
          });
    },
    _listInspectionPlans: function (_page = 1, _rows = 10) {
      this.queryParams.pageNum = _page;
      this.queryParams.pageSize = _rows;
      this.$fly
          .post(queryInspectPlanUrl, this.queryParams)
          .then((res) => {
            if (_page === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: _page,
                pageSize: _rows,
              });
            }
            this.inspectionPlans = res.data.datas;
          });
    },
    _openAddInspectionPlanModal: function (params) {
      this.$vc.emit(this.$route.path,
          "addInspectionPlan",
          "openAddInspectionPlanModal",
          {
            orgId: this.queryParams.orgId,
            operatorId: this.queryParams.operatorId,
            ...params,
          }
      );
    },
    _openEditInspectionPlanModel: function (_inspectionPlan) {
      this.$vc.emit(this.$route.path,
          "editInspectionPlan",
          "openEditInspectionPlanModal",
          _inspectionPlan
      );
    },
    _openDeleteInspectionPlanModel: function (_inspectionPlan) {
      this.$CSDialog.confirm({
        title: '提示',
        message: '确定删除吗?',
        onConfirm: () => {
          this.$fly
              .post(deleteInspectPlanUrl, {
                planId: _inspectionPlan.id,
                regionId: this.$vc.getCurrentRegion().communityId,
                operatorId: this.$vc.getCurrentStaffInfo().id,
              })
              .then((res) => {
                if (res?.code !== 0) {
                  return;
                }
                this.$CSDialog.instance.closeDialog();
                this._listInspectionPlans();
              });
        }
      })
    },
    _queryInspectionPlanMethod: function () {
      this._listInspectionPlans(DEFAULT_PAGE, DEFAULT_ROWS);
    },
    openSetInspectorModal(inspectPlanInfo) {
      this.$vc.emit(this.$route.path, "editInspectorModal", "openSetInspectorModal", {
        ...inspectPlanInfo,
        orgId: this.queryParams.orgId,
        operatorId: this.queryParams.operatorId,
      });
    },
    _changeInspectionPlanState(params) {
      let scheduleConfig = {
        startTime: params.startTime,
        endTime: params.endTime,
      };
      if (params.scheduleType == 5) {
        scheduleConfig.dates = params.scheduleDates;
      }
      if (params.scheduleType == 2) {
        scheduleConfig.days = params.scheduleDates;
      }
      const inspectionPlanStateInfo = {
        id: params.id,
        regionId: this.$vc.getCurrentRegion().communityId,
        orgId: params.orgId,
        type: params.type,
        name: params.name,
        routeId: params.routeId,
        operatorId: this.$vc.getCurrentStaffInfo().id,
        scheduleType: params.scheduleType,
        scheduleConfig: JSON.stringify(scheduleConfig),
        enable: params.enable,
      };
      return this.$fly
          .post(editInspectPlanUrl, inspectionPlanStateInfo)
          .then((res) => {
            if (res.code !== 0) {
              return false;
            }
            return true;
          });
    },
    _openEnabledInspectionPlanModel: function (params) {
      this.$CSDialog.confirm({
        title: '提示',
        message: '确定启用吗？',
        onConfirm: () => {
          this._changeInspectionPlanState({...params, orgId: this.queryParams.orgId, enable: true})
              .then(res => {
                if (res) {
                  this.$CSDialog.instance.closeDialog();
                  this.$vc.toast("操作成功！");
                  this._listInspectionPlans(this.queryParams.pageNum, this.queryParams.pageSize);
                }
              })
        }
      })
    },
    _openDisabledInspectionPlanModel: function (_inspectionPlan) {
      this.$CSDialog.confirm({
        title: '提示',
        message: '确定停用吗?',
        onConfirm: () => {
          this._changeInspectionPlanState({..._inspectionPlan, orgId: this.queryParams.orgId, enable: false})
              .then(res => {
                if (res) {
                  this.$CSDialog.instance.closeDialog();
                  this.$vc.toast("操作成功！");
                  this._listInspectionPlans(this.queryParams.pageNum, this.queryParams.pageSize);
                }
              })
        }
      })
    },
    _moreCondition: function () {
      if (this.inspectionPlanManageInfo.moreCondition) {
        this.inspectionPlanManageInfo.moreCondition = false;
      } else {
        this.inspectionPlanManageInfo.moreCondition = true;
      }
    },
    // 判断权限
    judgmentAuthority: function () {
      let udutyType = Number(this.$vc.getCurrentStaffInfo().dutyType);
      if ([1001, 1002, 1012].includes(udutyType)) {
        let types = {};
        for (let firstKey in this.inspectPlanType) {
          for (let secondKey in this.inspectPlanType[firstKey]) {
            const secondKeyMapping = {
              105:
                  "保洁-" +
                  this.inspectPlanType[firstKey][secondKey],
              204:
                  "客服-" +
                  this.inspectPlanType[firstKey][secondKey],
              305:
                  "安保-" +
                  this.inspectPlanType[firstKey][secondKey],
              415:
                  "工程-" +
                  this.inspectPlanType[firstKey][secondKey],
            };
            types[secondKey] =
                secondKeyMapping[secondKey] ||
                this.inspectPlanType[firstKey][secondKey];
          }
        }
        return types;
      }
      return this.inspectPlanType[udutyType];
    },
  },
};
</script>
<style>
</style>
